import styled, { css } from 'styled-components'
import { Typography } from 'antd'
import { IMaterialItem } from '.'
import { ISimplifiedVisualMaterial } from '@libs/api'
import { color, rgbaColor, borderRadius, padding } from '@libs/theme/mixins'
import { Container as _Container, Grid, IconButton } from '@libs/components'
import { OrientationType } from '@libs/api/build/material/dtos'

const Frame = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: 5px solid ${color('primary')};
	border-radius: ${borderRadius('md')};
	z-index: 1;
`

const Wrapper = styled.div`
	position: relative;
`

const Container = styled(_Container)<{
	$disabled: IMaterialItem['dragDisabled']
	$selectable: IMaterialItem['selectable']
	$selected: ISimplifiedVisualMaterial['isSelected']
	$showDisabledInteraction?: boolean
}>`
	> * {
		user-select: none;
	}

	.ant-input {
		padding: 0 ${padding('xxs')} 0 0;
		border-bottom: 1px solid ${color('gray')};
		border-radius: unset;
		box-shadow: inset 0px -3px 5px ${color(['gray', '200'])};

		::-webkit-input-placeholder {
			font-style: italic;
		}

		:-moz-placeholder {
			/* Firefox 18- */
			font-style: italic;
		}

		::-moz-placeholder {
			/* Firefox 19+ */
			font-style: italic;
		}
	}

	svg {
		min-width: 16px;
	}

	.ant-select-focused {
		> span {
			display: none !important;
		}
	}

	.ant-select-borderless {
		min-width: auto;
		width: 100%;

		.ant-select-selector {
			padding-left: 0;
		}

		.ant-select-selection-search {
			left: 0;
			border-bottom: 1px solid ${color('gray')};
		}

		* {
			height: 36px !important;
			line-height: 36px !important;
		}
	}

	${({ $disabled, $selectable, $showDisabledInteraction }) => css`
		height: 100%;
		border: 2px solid ${color('gray')};
		border-radius: ${borderRadius('md')};
		overflow: hidden;

		${!$disabled &&
		css`
			cursor: grab;
		`}
		${$selectable &&
		css`
			cursor: pointer;
		`}
		${$showDisabledInteraction &&
		css`
			cursor: not-allowed;
		`}
	`}
`

const Name = styled(Typography.Text)`
	border-bottom: 1px solid transparent; // to fix jumping when entering edit mode
`

const ImageWrapper = styled.div<{ $clickable: boolean; $forceVerticalLayout?: boolean; $orentation: OrientationType }>`
	display: flex;
	justify-content: center;
	position: relative;
	cursor: ${props => props.$clickable && 'pointer'};
	overflow: hidden;
	height: 0;
	padding-top: ${({ $forceVerticalLayout }) => ($forceVerticalLayout ? '140%' : '56.25%')};

	img,
	video {
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		object-fit: cover;
		z-index: 0;

		${({ $orentation, $forceVerticalLayout }) =>
			$orentation === 'Landscape' || $forceVerticalLayout
				? css`
						width: 100%;
				  `
				: css`
						margin: auto;
				  `}
	}

	video {
		pointer-events: none;
		user-select: none;
		object-fit: contain;
	}
`

const InactiveOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: ${rgbaColor(['gray', '900'], 0.6)};
	z-index: 1;
`

const MaterialTagList = styled(Grid.Row)`
	position: absolute;
	top: ${padding('xxs')};
	right: ${padding('xxs')};
	z-index: 2;
	justify-content: flex-end;

	.ant-tag {
		margin-right: 0;
	}
`

const EditScannerButton = styled(IconButton)`
	position: absolute;
	bottom: ${padding('xxs')};
	right: ${padding('xxs')};
	z-index: 1;
`

export default {
	Name,
	Container,
	ImageWrapper,
	InactiveOverlay,
	MaterialTagList,
	Frame,
	Wrapper,
	EditScannerButton,
}
