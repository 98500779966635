import { Container, UploadZone, useHideModal } from '@libs/components'
import { Button, message, Modal } from 'antd'
import React, { FC, Fragment, useEffect, useMemo } from 'react'
import { useTaskUploaderContext } from '../TaskUploader/context'
import { v4 as uuidv4 } from 'uuid'
import { RcFile } from 'antd/es/upload'
import { useLocalization } from '@libs/localization'
import { ExceptionType, TaskModalCommonProps } from '../types'
import { Close24 } from '@carbon/icons-react'
import TaskModalTitle from 'modules/common/TaskModalTitle'
import { useOrderLineById } from 'modules/cases/CaseOrderDetailsPageContent/hooks'
import { useUsersBranchById } from 'modules/branches/hooks'
import { useMutation } from 'react-query'
import { TasksApi } from '@libs/api'
import { useTasksQueriesInvalidation } from '../hooks'
import { ALLOWED_NON_VIDEO_EXTENSIONS, BASE_NON_VIDEO_EXTENSIONS } from './helpers'
import * as Sentry from '@sentry/nextjs'

const notAllowedFileNamesRegex = /[æøåÆØÅÖö,;:´¨'!"]/

export interface TaskUploaderModalProps extends TaskModalCommonProps {
	supportVideo?: boolean
}

const nonVideoTypes = `image/gif,image/png,image/jpg,image/jpeg,application/pdf,${ALLOWED_NON_VIDEO_EXTENSIONS.join(
	','
)}`

const TaskUploaderModal: FC<TaskUploaderModalProps> = ({ task, onTaskComplete, onTaskCancel, supportVideo }) => {
	const hideModal = useHideModal()
	const { state, initialize, upload, remove, seal } = useTaskUploaderContext()
	const entry = useMemo(() => state.entries.find(candidate => candidate.task.id === task.id), [state.entries, task.id])
	const uploadedMaterials = entry?.material.filter(candidate => candidate.status === 'done').length || 0
	const { data: foundOrderLine } = useOrderLineById({ orderId: task.orderId, orderlineId: task.orderlineId })
	const foundBranch = useUsersBranchById(task.branchId)
	const minQuantity = foundOrderLine?.orderedQuantity || 0
	const maxQuantity = foundBranch?.maxImageUploaded || 0
	const isSubmitDisabled =
		!entry ||
		uploadedMaterials < minQuantity ||
		entry.material.some(candidate => candidate.status === 'uploading' || candidate.status === 'error')
	const invalidateTaskQueries = useTasksQueriesInvalidation()

	const { mutateAsync: completeTask } = useMutation(TasksApi.completeTask, {
		onSuccess: response => {
			if (response.success) {
				message.success(t('modals.uploader.success'))
				invalidateTaskQueries()
				return
			}

			message.error(t('modals.uploader.failed'))
		},
	})
	const { t } = useLocalization()

	useEffect(() => {
		initialize(task)
	}, [])

	const supportedMimeTypes = useMemo(() => {
		return supportVideo ? `video/*` : nonVideoTypes
	}, [supportVideo])

	return (
		<Modal
			visible
			closeIcon={<Close24 />}
			width={960}
			title={<TaskModalTitle title={task.productName} caseId={task.caseReference} />}
			footer={
				<Button
					type={'primary'}
					disabled={isSubmitDisabled}
					onClick={async () => {
						if (uploadedMaterials < minQuantity) {
							message.error(t('modals.uploader.minUploadMessage', { min: minQuantity }))
							return
						}

						try {
							await seal(task.id)
							await completeTask(task.id)

							if (onTaskComplete) {
								onTaskComplete(task)
								return
							}

							hideModal()
						} catch (err) {
							message.error((err as ExceptionType).message)
						}
					}}
				>
					{t('common.actions.completeTask')}
				</Button>
			}
			onCancel={onTaskCancel ? () => onTaskCancel(task) : hideModal}
		>
			<UploadZone
				multiple
				fileList={entry?.material.map(({ id, fileName, status, error, percent }) => ({
					uid: id,
					name: fileName,
					status,
					error,
					percent,
				}))}
				accept={supportedMimeTypes}
				maxCount={maxQuantity}
				listType={'text'}
				text={t('modals.uploader.uploadInstructions')}
				hideAnchorIcon
				beforeUpload={file => {
					const extension = file.name.substring(file.name.lastIndexOf('.')).toLocaleLowerCase()

					const isSupportedFileType = supportVideo
						? file.type.includes('video')
						: file.name.includes('.xmp') ||
						  BASE_NON_VIDEO_EXTENSIONS.concat(ALLOWED_NON_VIDEO_EXTENSIONS).includes(extension)

					if (!isSupportedFileType) {
						message.error(t('modals.uploader.typeNotSupported'))
						return false
					}
					if (notAllowedFileNamesRegex.test(file.name)) {
						message.error(t('modals.uploader.invalidFileName'))
						return false
					}
					if (file.name.indexOf('.') !== file.name.lastIndexOf('.')) {
						message.error(t('modals.uploader.dotNotSupported'))
						return false
					}
					return true
				}}
				hint={
					<Container space={'xxs'} spaceDirection={'horizontal'}>
						<span>{t('modals.uploader.minQuantityHint', { min: minQuantity })}</span>
						{!!maxQuantity && (
							<Fragment>
								<span>/</span>
								<span>{t('modals.uploader.maxQuantityHint', { max: maxQuantity })}</span>
							</Fragment>
						)}
					</Container>
				}
				onRemove={async ({ uid }) => {
					try {
						await remove({ taskId: task.id, materialUuid: uid })
					} catch (err) {
						message.error((err as Error).message)
					}
				}}
				customRequest={({ file }) => {
					const materialFile = file as RcFile
					const formattedFileName = materialFile.name.replace(/[^\w.-_]/gi, '')
					const hasSameName = entry?.material.some(({ fileName }) => formattedFileName === fileName) || false

					if (process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_LOG_UPLOADS === 'true') {
						Sentry.captureEvent({
							message: 'Before file upload',
							extra: {
								url: window.location.href,
								taskId: task.id,
								fileName: materialFile.name,
								stateEntries: JSON.stringify(
									state.entries.map(({ task: entryTask, material }) => ({
										taskId: entryTask.id,
										materialNames: material.map(({ fileName }) => fileName),
									}))
								),
								branchId: task.branchId,
								caseId: task.caseId,
							},
						})
					}
					return upload({
						taskId: task.id,
						materialUuid: uuidv4(),
						materialFile,
						hasSameName,
					})
				}}
			/>
		</Modal>
	)
}

export default TaskUploaderModal
