import React, { FC } from 'react'
import { Modal, Button, Space } from 'antd'
import { useHideModal } from '@libs/components'
import { ISimplifiedVisualMaterial } from '@libs/api'
import { useLocalization } from '@libs/localization'
import { Close24 } from '@carbon/icons-react'

export interface CloudScannerLinksModalProps {
	cloudScannerLinks: ISimplifiedVisualMaterial['cloudScannerLinks']
}

const CloudScannerLinksModal: FC<CloudScannerLinksModalProps> = ({ cloudScannerLinks }) => {
	const hideModal = useHideModal()
	const { t } = useLocalization()

	return (
		<Modal
			visible
			closeIcon={<Close24 />}
			title={t('modals.cloudScannerLinks.title')}
			onCancel={hideModal}
			footer={null}
		>
			<Space direction="vertical" align="center" style={{ width: '100%' }}>
				{cloudScannerLinks.map(({ type, url }) => (
					<Button key={type} type="primary" href={url} target="_blank">
						{t(`cases.cloudScanner.linkType.${type}`)}
					</Button>
				))}
			</Space>
		</Modal>
	)
}

export default CloudScannerLinksModal
