import { ISimplifiedVisualMaterial } from '@libs/api'
import { useLocalization } from '@libs/localization'
import { isVideoFormat } from '@libs/util'
import { Tag, Tooltip } from 'antd'
import React, { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { DEFAULT_IMG_SOURCE } from '.'

import Styled from './Styled'
import { Grid } from '@libs/components'
import { RequestQuote20 } from '@carbon/icons-react'
import { useShowModal } from 'modules/common/GlobalModals'

export interface IMaterialItemThumbnail
	extends Pick<
		ISimplifiedVisualMaterial,
		| 'thumbnailUrl'
		| 'source'
		| 'sourceType'
		| 'updatedDate'
		| 'description'
		| 'isActive'
		| 'id'
		| 'fileName'
		| 'thumbnailOrientation'
		| 'cloudScannerLinks'
	> {
	/** Image click handler */
	onImageClick?: (id: ISimplifiedVisualMaterial['id']) => void
	tags: string[]
	/** Applies vertical wrapper styles */
	forceVerticalLayout?: boolean
	/** Removes overlay for inactive materials */
	disabledInactiveOverlay?: boolean
	showDisabledInteraction?: boolean
	isIFPMaterial: boolean
	showHighQualityImage?: boolean
	isEditVisible?: boolean
}

const MaterialItemThumbnail: FC<IMaterialItemThumbnail> = React.memo(
	({
		tags,
		fileName,
		onImageClick,
		id,
		forceVerticalLayout,
		thumbnailOrientation,
		showDisabledInteraction,
		disabledInactiveOverlay,
		isActive,
		thumbnailUrl,
		source,
		sourceType,
		isIFPMaterial,
		showHighQualityImage,
		isEditVisible = false,
		cloudScannerLinks,
	}) => {
		const { t } = useLocalization()
		const onImageLoadError = useCallback(
			(event: SyntheticEvent<HTMLImageElement, Event>) => ((event.target as HTMLImageElement).src = DEFAULT_IMG_SOURCE),
			[]
		)
		const showModal = useShowModal()
		const [editScannerHovered, setEditScannerHovered] = useState(false)

		const isVideo = useMemo(
			() => isVideoFormat(thumbnailUrl) || sourceType.toLowerCase() === 'video',
			[thumbnailUrl, sourceType]
		)

		const imgSrc = useMemo(
			() =>
				isIFPMaterial
					? '/placeholders/ifp-placeholder.jpeg'
					: showHighQualityImage
					? source
					: thumbnailUrl || DEFAULT_IMG_SOURCE,
			[showHighQualityImage, source, thumbnailUrl, isIFPMaterial]
		)
		return (
			<Tooltip title={fileName} overlayStyle={editScannerHovered ? { display: 'none' } : undefined}>
				<Styled.ImageWrapper
					$clickable={!!onImageClick}
					onClick={() => onImageClick?.(id)}
					$forceVerticalLayout={forceVerticalLayout}
					$orentation={thumbnailOrientation}
				>
					{((!disabledInactiveOverlay && !isActive) || showDisabledInteraction) && <Styled.InactiveOverlay />}
					{isVideo ? (
						<video width="100%" height="100%" preload="metadata">
							<source src={thumbnailUrl || `${source}#t=1.3`} />
						</video>
					) : (
						<img
							src={imgSrc}
							onError={onImageLoadError}
							style={{
								WebkitTouchCallout: 'none',
								userSelect: 'none',
							}}
							alt={fileName}
						/>
					)}
					{isEditVisible && cloudScannerLinks.length > 0 && (
						<Styled.EditScannerButton
							icon={<RequestQuote20 />}
							type="primary"
							size="small"
							title={t('cases.cloudScanner.edit')}
							onMouseEnter={() => setEditScannerHovered(true)}
							onMouseLeave={() => setEditScannerHovered(false)}
							onClick={e => {
								e.stopPropagation()

								if (cloudScannerLinks.length === 1) {
									window.open(cloudScannerLinks[0].url)
									return
								}

								showModal('material.scanner.links', { cloudScannerLinks })
							}}
						/>
					)}
					{tags.length > 0 && (
						<Styled.MaterialTagList gutter="xxs">
							{tags.map(tagTextKey => (
								<Grid.Col key={tagTextKey}>
									<Tag color="gold">{t(`materials.tags.${tagTextKey}`)}</Tag>
								</Grid.Col>
							))}
						</Styled.MaterialTagList>
					)}
				</Styled.ImageWrapper>
			</Tooltip>
		)
	}
)

export default MaterialItemThumbnail
