import React, { FC } from 'react'
import { SortableElement } from 'react-sortable-hoc'
import Styled from './Styled'
import { ISimplifiedVisualMaterial } from '@libs/api'
import MaterialItemThumbnail, { IMaterialItemThumbnail } from './MaterialItemThumbnail'
import MaterialItemMeta, { IMaterialItemMeta } from './MaterialItemMeta'

export const DEFAULT_IMG_SOURCE = '/placeholders/image-placeholder.jpeg'

export interface IMaterialItem
	extends IMaterialItemThumbnail,
		IMaterialItemMeta,
		Pick<ISimplifiedVisualMaterial, 'isSelected' | 'cloudScannerLinks' | 'type'> {
	/** Dragging state */
	dragDisabled?: boolean
	/** Selectable state */
	selectable?: boolean
	/** Name editing callback */
	onDescriptionEdit?: (
		name: ISimplifiedVisualMaterial['description'],
		materialId: ISimplifiedVisualMaterial['id']
	) => void
	/** Image click handler */
	onImageClick?: (id: ISimplifiedVisualMaterial['id']) => void
	/** Removes overlay for inactive materials */
	disabledInactiveOverlay?: boolean
	showDisabledInteraction?: boolean
	hideInfo?: boolean
}

export const MaterialItem: FC<IMaterialItem> = React.memo(
	({ isSelected, dragDisabled, selectable, showDisabledInteraction, hideInfo, ...other }) => {
		return (
			<Styled.Wrapper>
				<Styled.Container
					display="flex"
					justifyContent="space-between"
					flexDirection="column"
					$disabled={dragDisabled}
					$selectable={selectable}
					$selected={isSelected}
					$showDisabledInteraction={showDisabledInteraction}
				>
					<MaterialItemThumbnail
						tags={other.tags}
						fileName={other.fileName}
						onImageClick={other.onImageClick}
						id={other.id}
						forceVerticalLayout={other.forceVerticalLayout}
						thumbnailOrientation={other.thumbnailOrientation}
						showDisabledInteraction={showDisabledInteraction}
						disabledInactiveOverlay={other.disabledInactiveOverlay}
						isActive={other.isActive}
						thumbnailUrl={other.thumbnailUrl}
						source={other.source}
						sourceType={other.sourceType}
						isIFPMaterial={other.isIFPMaterial}
						showHighQualityImage={other.showHighQualityImage}
						isEditVisible={!selectable && dragDisabled && other.type === 'Floorplan'}
						cloudScannerLinks={other.cloudScannerLinks}
					/>
					{!hideInfo && (
						<MaterialItemMeta
							onDescriptionEdit={other.onDescriptionEdit}
							updatedDate={other.updatedDate}
							description={other.description}
							id={other.id}
							isActive={other.isActive}
							caseId={other.caseId}
						/>
					)}
				</Styled.Container>
				{isSelected && <Styled.Frame />}
			</Styled.Wrapper>
		)
	}
)

export default React.memo(SortableElement(MaterialItem))
