import React, { useEffect, useMemo } from 'react'
import Head from 'next/head'
import { Typography, Card, Badge, Dropdown, Menu, message, Button } from 'antd'
import { Box24, Download24, Image24, ListChecked24 } from '@carbon/icons-react'
import { IconButton, ConfirmModalProps, Grid, Container, useHideModal, Skeleton } from '@libs/components'
import CaseOrders from '../CaseOrders'
import TextDocuments from '../TextDocuments'
import NonVisualMaterials from 'modules/material/NonVisualMaterials'
import VisualMaterial from 'modules/material/VisualMaterial'
import { useCaseDetailsPage, useCasePresentation, useSimplifiedCaseOrders, useTranslatedTexts } from '../hooks'
import { useShowModal } from 'modules/common/GlobalModals'
import EditableAddress from 'modules/common/EditableAddress'
import { useMutation, useQueryClient } from 'react-query'
import { CaseMaterialStatus, IAddress, MaterialApi, OrderStatus, TaskStatus } from '@libs/api'
import appRoutes from 'app.routes'
import { useLocalization } from '@libs/localization'
import Link from 'next/link'
import { useScreenMatch } from '@libs/theme'
import { SpacingMixinParamType } from 'styled-components'
import ErrorPage from 'modules/common/ErrorPage'
import Styled from './Styled'
import BackAnchor from 'modules/common/BackAnchor'
import QUERIES from 'modules/common/queries'
import { useResetPersistedFilter } from 'modules/common/hooks'
import { useCollagePricing } from 'modules/material/hooks'
import { useUserBranches } from 'modules/branches/hooks'

const CaseDetailsPageContent = () => {
	const {
		caseId,
		reference,
		totalTasks,
		useVisualMaterialsHookData,
		caseAddress,
		updateAddress,
		isCaseFetched,
		doesCaseExist,
		isCaseExistLoading,
		showError,
		foundCase,
	} = useCaseDetailsPage()
	const queryClient = useQueryClient()
	const presentation = useCasePresentation()
	const showModal = useShowModal()
	const hideModal = useHideModal()
	const { t } = useLocalization()

	const { data: orders = [], isFetched: areOrdersFetched } = useSimplifiedCaseOrders(caseId!)
	const { translatedTexts = [], isFetched: areTextsFetched } = useTranslatedTexts(caseId!)
	const { isFetched: areCollagePricingFetched } = useCollagePricing(caseId)
	const isArchived = foundCase?.materialStatus === CaseMaterialStatus.ARCHIVED
	const hasVisualMaterials = useVisualMaterialsHookData.visualMaterials.length > 0
	const hasTextMaterials = translatedTexts && translatedTexts.length > 0
	const isSkeletonActive = !(
		useVisualMaterialsHookData.isFetched &&
		areTextsFetched &&
		areOrdersFetched &&
		areCollagePricingFetched
	)
	const { data: branches = [] } = useUserBranches()
	const foundBranch = useMemo(
		() => branches.find(({ id }) => id === foundCase?.departmentId),
		[branches, foundCase?.departmentId]
	)

	const sortedOrders = useMemo(
		() => orders.slice().sort((a, b) => b.createdDate.getTime() - a.createdDate.getTime()),
		[orders]
	)
	const resetTaskFilter = useResetPersistedFilter('tasks')

	const { mutateAsync: archive } = useMutation(MaterialApi.archive, {
		onSuccess: response => {
			if (response.success) {
				message.success(t('modals.archiveMaterial.success'))
				hideModal()
				return
			}

			message.error(t('modals.archiveMaterial.error.common'))
		},
	})

	const { mutateAsync: recreateMaterials } = useMutation(MaterialApi.recreateMaterials, {
		onSuccess: response => {
			if (response.success) {
				message.success(t('modals.recreateMaterials.success'))
				queryClient.invalidateQueries(QUERIES.orderList)
				hideModal()
			} else {
				message.error(t('modals.recreateMaterials.failed'))
			}
		},
	})

	const selectedMaterialIds = useMemo(
		() => useVisualMaterialsHookData.visualMaterials.filter(({ isSelected }) => isSelected).map(({ id }) => id),
		[useVisualMaterialsHookData.visualMaterials]
	)

	useEffect(() => {
		if (useVisualMaterialsHookData.visualMaterials.length > 0) {
			// kick off prefetching asynchronously
			useVisualMaterialsHookData.visualMaterials
				.filter(({ sourceType }) => sourceType.toLowerCase() === 'image')
				.forEach(({ source, thumbnailUrl }) => {
					// prefetch for carousel fullsize view
					const sourceImage = new Image()
					sourceImage.src = source

					// prefetch for the list of thumbnails
					const thumbnailImage = new Image()
					thumbnailImage.src = thumbnailUrl
				})
		}
	}, [useVisualMaterialsHookData.visualMaterials])

	const isEligibleToArchive = useMemo(
		() =>
			sortedOrders.every(
				({ status }) =>
					status === OrderStatus.Delivered || status === OrderStatus.Invoiced || status === OrderStatus.Cancelled
			),
		[sortedOrders]
	)

	const match = useScreenMatch()
	if (isCaseExistLoading) return null

	if ((isCaseFetched && !doesCaseExist) || showError) {
		return <ErrorPage />
	}

	return (
		<>
			<Head>
				<title>{t('navigation.pageTitles.caseDetails', { caseId: reference })}</title>
			</Head>
			<Container padding={['xl', 0]}>
				<Grid.Row>
					<Grid.Col xs={24} xl={12}>
						<Card title={t('cases.pages.details.informationCardTitle')}>
							{isSkeletonActive ? (
								<Skeleton.Paragraph active titleWidth={300} rows={4} rowWidth={[200, 80, 280]} />
							) : (
								<>
									<Styled.AnchorContainer>
										<BackAnchor />
									</Styled.AnchorContainer>
									<EditableAddress
										address={caseAddress as IAddress}
										otherBranchCountryCode={foundBranch?.countryCode}
										updateAddress={address => {
											if (caseId) {
												updateAddress({
													caseId,
													street: address.street,
													buildingNumber: address.houseNumber,
													buildingFloor: address.floor,
													buildingDoor: address.appartmentNumber,
													cityArea: address.area,
													zip: address.postNumber,
													city: address.city,
												})
											}
										}}
									/>
									<Typography.Paragraph>
										{t('cases.pages.details.reference')}: {reference}
									</Typography.Paragraph>
									<Typography.Paragraph type="secondary">{t('common.terms.actions')}</Typography.Paragraph>
									<Container
										display={'flex'}
										justifyContent={match.map({ xxs: 'space-between', xs: 'flex-start' })}
										space={match.map<SpacingMixinParamType>({ xxs: 0, xs: 'sm' })}
										spaceDirection={'horizontal'}
									>
										{hasVisualMaterials && caseId && (
											<>
												<IconButton
													title={
														isEligibleToArchive
															? t('common.actions.archive')
															: t('cases.pages.details.archiveDisabledOpenOrder')
													}
													disabled={!isEligibleToArchive}
													onClick={() =>
														showModal('material.archive', {
															title: t('modals.archiveMaterial.title'),
															message: t('modals.archiveMaterial.warningNote'),
															cancelLabel: t('common.actions.cancel'),
															onConfirm: async () => {
																await archive(caseId)
															},
														} as ConfirmModalProps)
													}
												>
													<Box24 />
												</IconButton>
												<IconButton
													title={t('common.actions.download')}
													onClick={() =>
														showModal('material.download', {
															caseId,
															selectedMaterialIds,
														})
													}
												>
													<Download24 />
												</IconButton>
												<Dropdown
													placement="bottomRight"
													overlay={
														<Menu>
															{presentation.options.map(({ filter, label }) => (
																<Menu.Item key={filter}>
																	<Link href={appRoutes.presentation(caseId, filter)}>
																		<a target="_blank">{label}</a>
																	</Link>
																</Menu.Item>
															))}
														</Menu>
													}
												>
													<IconButton>
														<Image24 />
													</IconButton>
												</Dropdown>
											</>
										)}
										<Badge count={totalTasks} offset={[-4, 4]}>
											<Link href={appRoutes.tasks.list(caseId, [TaskStatus.Open, TaskStatus.Partial])} passHref>
												<IconButton
													title={t('common.terms.tasks')}
													onClick={() => {
														resetTaskFilter()
													}}
												>
													<ListChecked24 />
												</IconButton>
											</Link>
										</Badge>
									</Container>
								</>
							)}
						</Card>
					</Grid.Col>
					<Grid.Col xs={24} xl={12}>
						<CaseOrders caseId={caseId!} orders={sortedOrders} isLoading={isSkeletonActive} />
					</Grid.Col>
					{isSkeletonActive ? (
						<Grid.Col xs={24}>
							<Card>
								<Skeleton.Paragraph active titleWidth={200} rows={18} />
							</Card>
						</Grid.Col>
					) : (
						<>
							{(hasVisualMaterials || isArchived) && (
								<Grid.Col xs={24}>
									{isArchived ? (
										<Styled.NoMaterialsAlert
											message={t('cases.pages.details.archivedMaterialsTitle')}
											description={t('cases.pages.details.archivedMaterialsDesc')}
											type="error"
											action={
												<Button
													size="small"
													danger
													onClick={() =>
														showModal('material.recreate', {
															title: t('modals.recreateMaterials.title'),
															message: t('modals.recreateMaterials.message'),
															confirmLabel: t('modals.recreateMaterials.confirm'),
															cancelLabel: t('common.actions.cancel'),
															onConfirm: async () => {
																if (caseId) await recreateMaterials(caseId)
															},
														} as ConfirmModalProps)
													}
												>
													{t('cases.actions.recreateMaterials')}
												</Button>
											}
										/>
									) : (
										<VisualMaterial
											caseId={caseId!}
											branchId={foundCase?.departmentId}
											caseAddress={caseAddress?.full}
											{...useVisualMaterialsHookData}
										/>
									)}
								</Grid.Col>
							)}
							{hasTextMaterials && caseId && (
								<Grid.Col xs={24} xl={12}>
									<TextDocuments translatedTexts={translatedTexts} caseId={caseId} />
								</Grid.Col>
							)}
							<Grid.Col xs={24} xl={hasTextMaterials ? 12 : 24}>
								<NonVisualMaterials caseId={caseId!} wideView={!hasTextMaterials} />
							</Grid.Col>
						</>
					)}
				</Grid.Row>
			</Container>
		</>
	)
}

export default CaseDetailsPageContent
